<template>
	<div>
		<div>
			<v-btn v-if="isText" color="success" @click="showShortCodesDialog = true">
				System Short Codes
			</v-btn>

			<v-btn
				v-else
				@click="showShortCodesDialog = true"
				:color="'success'"
				large
				icon
			>
				<v-icon large>
					mdi-lightning-bolt
				</v-icon>
			</v-btn>
		</div>
		<v-dialog
			v-if="showShortCodesDialog"
			v-model="showShortCodesDialog"
			max-width="800"
			persistent
			scrollable
		>
			<v-card>
				<v-card-title class="text-h5 transparent-3">
					System Short Codes
					<v-spacer></v-spacer>

					<v-btn
						color="success"
						icon
						@click="createUpdateSystemShortCodeDialogShow()"
					>
						<v-icon>mdi-pencil</v-icon>
					</v-btn>

					<v-btn color="secondary" icon @click="getSystemShortCodes()">
						<v-icon>mdi-refresh</v-icon>
					</v-btn>

					<v-btn color="error" icon @click="showShortCodesDialog = false">
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-card-title>
				<v-card-text class="my-3">
					<v-row>
						<v-col cols="3">Name</v-col>
						<v-col cols="7">Body</v-col>
						<v-col cols="2">Actions</v-col>
					</v-row>
					<v-row v-for="(shortCode, index) in systemShortCodes" :key="index">
						<v-col cols="3">
							<span>{{ shortCode.payload.name }} </span>
						</v-col>
						<v-col cols="7" v-html="shortCode.payload.body"> </v-col>
						<v-col cols="2">
							<v-btn
								color="success"
								icon
								@click="createUpdateSystemShortCodeDialogShow(shortCode)"
							>
								<v-icon>mdi-update</v-icon>
							</v-btn>
							<v-btn
								color="primary"
								icon
								@click="$helpers.copyText(`{{${shortCode.payload.name}}}`)"
							>
								<v-icon>mdi-content-copy</v-icon>
							</v-btn>
							<v-btn
								@click="deleteSystemShortCode(shortCode.id)"
								v-if="shortCode.payload.is_default == 'No'"
								color="error"
								icon
							>
								<v-icon>mdi-delete</v-icon>
							</v-btn>
						</v-col>
					</v-row>
				</v-card-text>
				<v-card-actions class="transparent-2">
					<v-btn
						color="green darken-1"
						outlined
						block
						@click="showShortCodesDialog = false"
					>
						Close
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<template v-if="createUpdateSystemShortCodeDialog">
			<createUpdateSystemShortCodeDialog
				@refresh="refresh()"
				@closeDialog="createUpdateSystemShortCodeDialogClose()"
				:dialogModel="createUpdateSystemShortCodeDialog"
				:selectedModel="selectedShortCode"
				:user="user"
				scrollable
			/>
		</template>
	</div>
</template>

<script>
	import createUpdateSystemShortCodeDialog from "@/components/dialogs/createUpdateSystemShortCodeDialog";

	export default {
		components: { createUpdateSystemShortCodeDialog },
		props: {
			user: { required: true, default: null },
			isText: { required: false, default: true }
		},
		data() {
			return {
				systemShortCodes: [],
				showShortCodesDialog: false,
				createUpdateSystemShortCodeDialog: false,
				selectedShortCode: null,
				isValidForm: false
			};
		},
		mounted() {
			this.getSystemShortCodes();
		},
		computed: {},
		watch: {
			showShortCodesDialog(newVal) {
				if (newVal) this.getSystemShortCodes();
			}
		},
		methods: {
			refresh() {
				this.getSystemShortCodes();
				this.$emit("refresh");
			},
			createUpdateSystemShortCodeDialogShow(shortCode) {
				this.selectedShortCode = shortCode;
				this.createUpdateSystemShortCodeDialog = true;
			},
			createUpdateSystemShortCodeDialogClose() {
				this.selectedShortCode = null;
				this.createUpdateSystemShortCodeDialog = false;
			},
			async getSystemShortCodes() {
				let data = {
					agent_id: this.user?.agent?.id
				};

				return await this.post(
					`${this.baseUrl}/agent-portal/get-system-short-codes`,
					data,
					true
				).then(data => {
					if (data == "undefined") return;
					// this.showMessageFromResponseDta(data);
					if (data.code == 200) {
						this.systemShortCodes = data.data.system_short_codes;
					}
				});
			},
			async deleteSystemShortCode(systemShortCodeId) {
				if (
					!confirm("You sure you want to delete this custom system short code.")
				)
					return false;

				let data = {
					system_short_code_id: systemShortCodeId
				};

				return await this.post(
					`${this.baseUrl}/agent-portal/delete-system-short-code`,
					data,
					true
				).then(data => {
					if (data == "undefined") return;
					this.showMessageFromResponseDta(data);
					if (data.code == 200) {
						this.getSystemShortCodes();
					}
				});
			}
		}
	};
</script>

